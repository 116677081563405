


.HomePage-internal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 12.5%;
  width: fit-content;
  border-radius: 15px;
  padding: 40px;
  background: #3030303d;
  backdrop-filter: blur(10px);
  border: 1px solid #30303052;
  font-family: "Electrolize", sans-serif;
  font-weight: 400;
  font-style: normal;

}

.HomePage-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.logo img {
  width: 20%;
  height: 20%;
}

.vertical-text {
  position: fixed;
  bottom: 50px;
  left: 0px;
  text-transform: capitalize;
  transform: translate(-30%, -150%) rotate(90deg);
  font-size: 100px;
  font-weight: 900;
  color: white;
  opacity: 0.1;
  transition: 0.4s ease-in;
  cursor: pointer;
  font-family: "Electrolize", sans-serif;

}
.vertical-text:hover{
    letter-spacing: 2px;
    
}
.highlight{
    color: #00a706;
    font-style: bold;
}

.blob {
  position: absolute;
  width: 500px;
  height: 500px;
  background: linear-gradient(
    180deg,
    rgba(47, 184, 255, 0.42) 31.77%,
    #5c9df1 100%
  );
  mix-blend-mode: color-dodge;
  -webkit-animation: move 25s infinite alternate;
  animation: move 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.blob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.6),
    inset 100px 100px 0 0px #fa709a, inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;
}


@keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}
