/* src/App.css */

body {
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 100vh;
  background: #0e0e1e; /* fallback for old browsers */
}

.title {
  color: aliceblue;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px; */
}

button {
  margin: 10px;
  border: none;
  border-radius: 0px;
  padding: 10px 20px;
  cursor: pointer;
  color: white;
  font-family: "Electrolize", sans-serif;
  font-size: 14px;
  border: 1px solid white;
  transition: 0.2s ease-in;
}

.register-button {
  background: transparent;
  margin-top: 20px;
}

.register-button:hover {
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.8);
  background: white;
  color: black;
}

.register-options {
  display: flex;
  justify-content: space-around;
}
