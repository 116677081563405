/* .ag-theme-alpine {
    --ag-borders: none;
    --ag-row-border-style: dashed;
    --ag-row-border-width: 5px;
    --ag-row-border-color: rgb(150, 150, 200);
} */

.text{
    color: white !important;
    font:  "Electrolize", sans-serif;
}