.certificateRect {
  position: absolute;
  border: 2px solid red;
  pointer-events: none;
}

.Certificate_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  /* margin-top: 25%; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: fit-content;
  padding: 50px 30px;
  height: fit-content;

}

.Certificate_file{

    text-align: center;
    padding-left: 20%;
}

.Certificate_heading{
    text-align: center;
    font-size: 24px;
    font-weight: bolder;
    color: #ffffff;
  font-family: "Electrolize", sans-serif;
  margin-bottom: 30px;

}

.Certificate_fields{
    margin-left: 2rem;
    color: white;
    font-family: "Electrolize", sans-serif;
    
}