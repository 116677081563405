.entire_container{
  display: flex;
  justify-content: space-between;
}

.dashboard_container {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  padding: 4rem;
  margin-left: 2rem;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 20px auto;
  text-align: center;
}

.List_of_events{
  margin-left: 2rem;
}

input {
  margin: 5px;
  padding: 5px;
}

h3 {
  margin-top: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

.event-item {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 350px;
  margin: 10px 0;
}

button {
  background: #e9e9e9;
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.input_text {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  color: #333;
}
