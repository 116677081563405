/* Footer styling */
.footer {
  color: #ffffff;
  opacity: 0.5;
  font-family: "Electrolize", sans-serif;

  text-align: center;
  /* padding: 10px; */
  position: fixed;
  /* position: relative; */
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* Copyright notice */
.copyright {
  font-size: 14px;
  font-weight: bold;
  padding: 12px;
}
