/* Navbar container */
/* .navbar-container{
  width: 100%;
  position: relative;
} */

.navbar {
  color: #0074d9;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  align-items: center;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); */
}

/* Logo styling */
.logo {
  font-weight: bold;
  margin: 2rem;
  margin-top: 5px;
}
.nitlogo{
  height: 70px !important;
  width: 70px !important;
  padding-right: 20px !important;
  margin-top: 5px !important;
}
.home-btn{
  background: transparent !important;
  font-size: 20px !important;
  color: rgb(255, 255, 255) !important;
  transition: 0.1s ease-in !important;
  padding: 5px 10px !important;
}

.home-btn:hover{
  background: white !important;
  color: black !important;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.8) !important;

}
/* Navigation links */
.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
  padding-bottom: 25%;
}

.nav-links a {
  text-decoration: none;
  color: #0074d9;
  font-weight: 600;
  font-size: 1.5rem;
}

/* Logout button */
.nav-links button {
  background-color: #0074d9;
  color: white;
  border: none;
  padding: 12px 16px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 1rem;
}

/* Hover effect on links and buttons */
.nav-links button:hover {
  background-color: lightblue;

  /* border-radius: 5px; */
}


/* @media screen and (max-width:1100px) {
  .navbar{
    height: 5%;
  }

  .nav-links li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding-bottom: 10%;
  }
} */