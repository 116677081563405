.file-input {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  color: #6b7280;
  margin-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 9999px;
  border: none;
  background-color: #4a5568;
  font-size: 0.625rem;
  color: #a0aec0;
}

.file-input:hover {
  background-color: #2d3748;
}