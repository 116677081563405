.form-internal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  /* border: 2px solid red; */
  margin-top:12.5%;
  position: relative;
  width: 25%;
  font-family: "Electrolize", sans-serif;
  backdrop-filter: blur(10px);

}

input{
  background: transparent !important;
  border-bottom: 2px solid white !important;
  color: white !important
}

input::placeholder{
  color: white;
}

.form-container{
  display: flex;
  justify-content: center;
  align-items: center ;
  /* border: 2px solid red; */
  flex-direction: column;
  color: white;
}

.input_text {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  /* transition: background 0.3s; */
}

/* @media screen and (max-width:1100px) {
  .form-internal{
    margin: 12.5%;
  }
} */

.submit-btn{
  background: #3030303d;
  backdrop-filter: blur(10px);
  border: 1px solid white;
  font-family: "Electrolize", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-family: "Electrolize", sans-serif;
}

.submit-btn:hover{
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.8);
  background: white;
  color: black;
}

